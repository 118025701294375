import * as React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const IndexPostContainer = styled.div`
  margin-bottom: 24px;
  background-color: white;
  transition: background-color 0.2s;
  padding: 24px;

  &:hover {
    background-color: var(--main-color-mid);
    transition: background-color 0.2s;
  }

  p {
    font-style: italic;
    margin: 0;
  }

  @media only screen and (min-width: 1000px) {
    padding: 40px;
  }
`

const IndexPost = ({ post }) => {
  const path = String(
    post.title
      .replace(/ú/g, "u")
      .replace(/ó/g, "o")
      .replace(/í/g, "i")
      .replace(/é/g, "e")
      .replace(/á/g, "a")
      .replace(/ñ/g, "n")
      .replace(/[^A-Za-z\s]+/g, "")
      .replace(/\s+/g, "-")
      .toLowerCase()
  )

  return (
    <Link
      style={{ textDecoration: "none", color: "var(--text-color)" }}
      to={path}
    >
      <IndexPostContainer>
        <h3>{post.title}</h3>
        <p>{post.date}</p>
      </IndexPostContainer>
    </Link>
  )
}

export default IndexPost
