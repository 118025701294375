import * as React from "react"
import styled, { css } from "styled-components"
import SearchIcon from "../images/svg/search.svg"
import { useState } from "react"

const InputContainer = styled.div`
  display: flex;
  max-width: 100%;

  @media only screen and (min-width: 480px) {
    max-width: 300px;
  }

  input {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-right: none;
    border-radius: 4px 0 0 4px;
    padding: 12px 17px;
    font-family: "Open Sans", sans-serif;
    outline: none;
    width: 100%;
    color: var(--text-color);

    &:focus {
      background-color: var(--main-color-light);
      border: 1px solid var(--main-color);
      border-right: none;
    }
  }

  .iconContainer {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-left: none;
    border-radius: 0 4px 4px 0;
    padding: 12px 17px;
    display: flex;
    align-items: center;

    ${({ isFocused }) =>
      isFocused &&
      css`
        background-color: var(--main-color-light);
        border: 1px solid var(--main-color);
        border-left: none;

        svg path {
          stroke: var(--main-color);
        }
      `};
  }
`

const Input = ({ placeholder, parentCallback }) => {
  let [isFocused, setIsFocused] = useState(false)

  const onInput = event => {
    // emulates vue $emit
    parentCallback(event.target.value)
  }

  return (
    <>
      <InputContainer isFocused={isFocused}>
        <input
          name="search"
          autoComplete="off"
          placeholder={placeholder}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onInput={onInput}
        />
        <div className="iconContainer">
          <SearchIcon />
        </div>
      </InputContainer>
    </>
  )
}

export default Input
