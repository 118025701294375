import * as React from "react"
import Input from "../components/input"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import IndexPost from "../components/indexPost"
import styled from "styled-components"
import { useState } from "react"

const IndexContainer = styled.div`
  .posts-container {
    margin-top: 40px;
  }
`

const IndexPage = ({ data }) => {
  let [query, setQuery] = useState("")

  const handleCallback = childData => {
    // becomes query from input component
    setQuery(childData)
  }

  const postList = data.allFile.edges

  const filteredPostList = query
    ? postList.filter(post =>
        post.node.childMarkdownRemark.frontmatter.title
          .toLowerCase()
          .replace(/ú/g, "u")
          .replace(/ó/g, "o")
          .replace(/í/g, "i")
          .replace(/é/g, "e")
          .replace(/á/g, "a")
          .includes(
            query
              .toLowerCase()
              .replace(/ú/g, "u")
              .replace(/ó/g, "o")
              .replace(/í/g, "i")
              .replace(/é/g, "e")
              .replace(/á/g, "a")
          )
      )
    : postList

  return (
    <Layout>
      <Seo title="Inicio" />
      <IndexContainer>
        <h2>Artículos</h2>
        <Input parentCallback={handleCallback} placeholder="Buscar" />
        <div className="posts-container">
          {filteredPostList.map((post, index) => (
            <IndexPost
              key={index}
              post={post.node.childMarkdownRemark.frontmatter}
            />
          ))}
          {!filteredPostList.length && (
            <p>
              <i>No se encontraron artículos que coincidan con la búsqueda.</i>
            </p>
          )}
        </div>
      </IndexContainer>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexQuery {
    allFile(
      filter: { sourceInstanceName: { regex: "/^blog$/" } }
      sort: { fields: childMarkdownRemark___frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              title
              date(formatString: "DD [de] MMMM YYYY", locale: "es-ES")
              file
            }
          }
        }
      }
    }
  }
`
